export default {
  name: 'NotesArea',
  props: {
    noteTitle: String,
    idCollapse: {
      type: Number,
      require: true
    },
    openedCollapse: {
      require: true
    }
  },
  methods: {
    openCollapse() {
      // Send current status of collapse to parent page
      const value = this.idCollapse === this.openedCollapse ? false : this.idCollapse
      this.$emit('openCollapsed', value)
    }
  }
}
