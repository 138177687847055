export default {
  name: 'Header',
  props: {
    logoImage: String,
    bgColorLogoImage: String
  },
  computed: {
    createBackgroundString() {
      return `${this.bgColorLogoImage}`
    }
  }
}
