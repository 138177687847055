import {
  CAMPAIGN_SMS_TYPE,
  CAMPAIGN_SERIAL_TYPE,
  CAMPAIGN_SMS_SERIAL_TYPE,
  CAMPAIGN_SERIAL_VIA_SMS_TYPE
} from '@constants'

export default {
  name: 'ButtonEnter',
  props: {
    btnColor: String,
    btnBackgroundImage: String,
    isExpired: {
      type: Number,
      required: true
    },
    isSerialCode: {
      type: String,
      default: '0'
    },
    authType: {
      type: Number,
      required: true
    },
    utmCampaign: String,
    utmMedium: String,
    utmSource: String
  },
  computed: {
    createRouter() {
      // campaign type is sms
      if (this.authType === CAMPAIGN_SMS_TYPE) {
        return {
          name: 'campaign.auth.sms',
          query: {
            utm_campaign: this.utmCampaign,
            utm_medium: this.utmMedium,
            utm_source: this.utmSource
          }
        }
      } else if (this.authType === CAMPAIGN_SERIAL_TYPE) { // campaign type is serial code
        return {
          name: 'campaign.auth.serial',
          query: {
            code: this.isSerialCode,
            utm_campaign: this.utmCampaign,
            utm_medium: this.utmMedium,
            utm_source: this.utmSource
          }
        }
      } else if (this.authType === CAMPAIGN_SMS_SERIAL_TYPE) { // campaign type is SMS + SerialCode
        return {
          name: 'campaign.auth.smsserial',
          query: {
            utm_campaign: this.utmCampaign,
            utm_medium: this.utmMedium,
            utm_source: this.utmSource
          }
        }
      } else if (this.authType === CAMPAIGN_SERIAL_VIA_SMS_TYPE) { // campaign type is serial code share via sms
        return {
          name: 'campaign.auth.serial',
          query: {
            utm_campaign: this.utmCampaign,
            utm_medium: this.utmMedium,
            utm_source: this.utmSource
          }
        }
      } else {
        return {
          name: 'campaign.error.404'
        }
      }
    },
    createBtnStyle() {
      return {
        '--btn-enter-bg': `url("${encodeURI(this.btnBackgroundImage)}"), ${this.btnColor}`
      }
    },
    createTextStyle() {
      return `color: ${this.btnTextColor}`
    }
  }
}
