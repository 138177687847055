import { DEFAULT_LOADING_FILL, DEFAULT_LOADING_WIDTH, DEFAULT_LOADING_HEIGHT } from '@constants'

export default {
  name: 'ScreenLoading',
  data() {
    return {
      loadingStyle: {
        fill: DEFAULT_LOADING_FILL,
        width: DEFAULT_LOADING_WIDTH,
        height: DEFAULT_LOADING_HEIGHT
      },
      errorContent: `<div class="wrong-content">
        <p>Oops!</p>
        <p>Something went wrong.<br>Please try again later</p>
        </div>`
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  methods: {
    updateOnlineStatus(e) {
      const {
        type
      } = e
      type === 'offline' ? document.getElementById('loading-page').innerHTML = this.errorContent : null
    }
  }
}
