export default {
  name: 'ButtonBack',
  props: {
    showBtn: {
      type: Boolean,
      required: true
    },
    campaignUrlName: {
      type: String,
      required: true
    },
    campaignUrl: {
      type: String,
      required: true
    },
    textButton: {
      type: String,
      default: 'Go Back'
    }
  },
  methods: {
    createRouteHaveCampaignUrl() {
      this.campaignUrl
        ? window.location.href = `/${this.campaignUrlName}/${this.campaignUrl}`
        : {}
    }
  }
}
