import { render, staticRenderFns } from "./ButtonSubmit.html?vue&type=template&id=3b2d0b15&scoped=true&external"
import script from "./ButtonSubmit.js?vue&type=script&lang=js&external"
export * from "./ButtonSubmit.js?vue&type=script&lang=js&external"
import style0 from "./ButtonSubmit.scss?vue&type=style&index=0&id=3b2d0b15&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b2d0b15",
  null
  
)

export default component.exports