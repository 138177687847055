import { render, staticRenderFns } from "./Footer.html?vue&type=template&id=b5f2f306&scoped=true&external"
import script from "./Footer.js?vue&type=script&lang=js&external"
export * from "./Footer.js?vue&type=script&lang=js&external"
import style0 from "./Footer.scss?vue&type=style&index=0&id=b5f2f306&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5f2f306",
  null
  
)

export default component.exports