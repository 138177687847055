import { DEFAULT_BUTTON_SUBMIT } from '@constants'

export default {
  name: 'ButtonSubmit',
  props: {
    btnColor: String,
    btnTextColor: String,
    canSubmit: Boolean,
    title: {
      type: String,
      required: true
    },
    textCheckingCode: String,
    isCallingProcessing: Boolean,
    serialType: Boolean,
    typeBtn: {
      type: String,
      default: DEFAULT_BUTTON_SUBMIT
    }
  },
  methods: {
    typeOfButton() {
      if (this.typeBtn === DEFAULT_BUTTON_SUBMIT) {
        return DEFAULT_BUTTON_SUBMIT
      }
    }
  },
  computed: {
    createBtnStyle() {
      return {
        '--btn-submit-color': this.btnColor,
        '--btn-submit-text-color': this.btnTextColor,
        '--btn-submit-arrow-color': this.btnTextColor
      }
    }
  }
}
